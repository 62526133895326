const normalizeSrc = (src) => {
  return src.startsWith("/") ? src.slice(1) : src;
}

export const loader = ({ src, width, quality }) => {
  const params = [`width=${width}`]
  if (quality) {
    params.push(`quality=${quality}`)
  }

  const paramsString = params.join(",");
  return !src.includes("/img/default.png")
    ? `${process.env.NEXT_PUBLIC_CLOUDFLARE_IMAGE_DOMAIN}/${paramsString}/${normalizeSrc(src)}`
    : `/img/default.png`;
}

export const getImage = (element, crop) => {
  if (
    element.images === undefined ||
    element.images.length === 0 ||
    !element.images[0].clippings ||
    element.images[0].clippings.length === 0
  ) {
    return "none";
  }

  const imgIndex = element.images[0].clippings.findIndex((el) => {
    return el._id === crop;
  });
  return imgIndex >= 0
    ? process.env.NEXT_PUBLIC_IMAGE_DOMAIN +
        element.images[0].clippings[imgIndex].url
    : process.env.NEXT_PUBLIC_IMAGE_DOMAIN + element.images[0].clippings[0].url;
};

export const getImageFromClippingList = (clippings, crop) => {
  
  if (!Array.isArray(clippings) || clippings.length === 0) {
    return {
        domain: process.env.NEXT_PUBLIC_IMAGE_DOMAIN,
        imgUrl: '#',
    }
  }

  const imgIndex = clippings.findIndex((el) => {
    return el._id === crop;
  });
  return imgIndex >= 0
    ? {
        domain: process.env.NEXT_PUBLIC_IMAGE_DOMAIN,
        imgUrl: clippings[imgIndex].url,
      }
    : {
        domain: process.env.NEXT_PUBLIC_IMAGE_DOMAIN,
        imgUrl: clippings[0].url,
      };
};