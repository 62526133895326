import Image from '@/shared/components/Image/new'
import styles from "./styles.module.css";
import Templates from "@/widgets/index";
import { getImage, loader } from "@/shared/functions/getImagesFunctions";
import { formatAuthorNames } from '@/shared/functions/formatAthorNames'

const NotasConBanner = (props) => {

  const MAIN_CROP = "3_2_630x420";
  const SECONDARY_CROP = "3_2_300x200";

  let mainContent = !props.content ? [] : [...props.content];
  const highlightedContent =
    mainContent.length > 0 ? [mainContent.shift()] : undefined;
  const content = mainContent;

  const getAuthors = (authors) => {
    let authorsList = [];
    if (authors != undefined && authors?.length) {
			const authorName = formatAuthorNames(authors[0].name);
      authors.length > 1
        ? authors
            .slice(0, 2)
            .map((element) => {
				     const elementName = formatAuthorNames(element.name);
             return authorsList.push(
                element.slug
                  ? `<a href=/autor/${element.slug}>${elementName}</a>`
                  : elementName
              )
            })
        : authorsList.push(
            authors[0].slug
              ? `<a href=/autor/${authors[0].slug}>${authorName}</a>`
              : authorName
          );
    }
    const parsedAuthors = authorsList.join(" y ");
    return parsedAuthors;
  };

  return (
    <div className={` ${styles["parent"]} ${styles['banner-'+props.bannerPosition]}`}>
      <div className={` ${styles["main-content"]}`}>
        {highlightedContent &&
          highlightedContent.map((element, index) => {
            const { url, title, authors, summary, subtitle } = element;
            const img = getImage(element, MAIN_CROP);
            const authorElement = getAuthors(authors);
            return (
              <div className={` ${styles["main-container"]}`} key={element._id + index}>
                <a href={url}>
                  <Image
                    src={img}
                    alt={title}
                    width={630}
                    height={420}
                    loader={loader}
                    className={styles['img']}

                  />
                </a>
                <div className={`${styles["main-txt-container"]}`}>
                  <a href={url}>
                    <h2>{title}</h2>
                  </a>
                  <div className={`${styles["summary"]}`} dangerouslySetInnerHTML={{ __html: summary }} />
                  {authorElement && (
                    <h5
                      dangerouslySetInnerHTML={{
                        __html: "Por " + authorElement,
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </div>

      <div className={`${styles['banner']}`}>
        <Templates.BannerCajaGrande bannerId={props.bannerId} isPreview={props.isPreview} />
      </div>

      <div className={`${styles["secondary-content"]}`}>
        {content &&
          content.map((element, index) => {
            const { url, title, subtitle } = element;
            const img = getImage(element, SECONDARY_CROP);
            return (
              <div className={` ${styles["secondary-container"]}`} key={element._id + index}>
                <a href={url}>
                  <Image
                    src={img}
                    alt={title}
                    width={300}
                    height={200}
                    loader={loader}
                    className={styles['img']}

                  />
                </a>
                <div className={`${styles["secondary-txt-container"]}`}>
                  <a href={url}>
                    <h3>
                      {subtitle} <span> {title}</span>
                    </h3>
                  </a>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NotasConBanner;
